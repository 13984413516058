<template>
  <div>
    <div v-show="showInformations" class="wrapper">
      <div ref="cardRef" class="wrapper__card">
        <div class="wrapper__card__header">
          <div class="left">
            <span>Consultar audiências</span>
          </div>
          <div class="right">
            <div>
              <z-input
                v-model="searchConnection"
                placeholder="Pesquisar"
                prependIcon="$search_rounded"
                type="text"
                :hideDetails="true"
              />
            </div>
            <div>
              <!-- <z-btn
                rounded
                primary
                text="AI"
                icon="mdi-chip"
                small
                class="mr-2"
                @click="goToPage('databaseInteractionPage')"
              /> -->
              <z-btn
                rounded
                primary
                text="Nova audiência"
                icon="mdi-plus"
                small
                @click="goToPage('addAudiencePage')"
              />
            </div>
          </div>
        </div>

        <z-tabs android :tabs="tabs">
          <template #audience>
            <div id="card_body_main" class="wrapper__card__body mt-3">
              <z-pagination
                auto-pagination
                icon-previous="mdi-chevron-left"
                icon-next="mdi-chevron-right"
                :items-per-page="getNumberOfElements"
                :number-of-items="
                  searchConnection ? filtered.length : audiences.lenth
                "
                :items="searchConnection ? filtered : audiences"
              >
                <template #data="{ paginatedItems }">
                  <div class="table-wrapper">
                    <z-table
                      class="table"
                      :headers="headers"
                      :items="paginatedItems"
                      icon-next="mdi-chevron-right"
                      icon-previous="mdi-chevron-left"
                      @sort="sortTable"
                    >
                      <template #no-data>
                        <div class="no-data-table">
                          <h3>Sem dados disponíveis.</h3>
                        </div>
                      </template>
                      <template v-slot:[`item.favorite`]="{ item }">
                        <div class="d-flex align-center justify-center">
                          <img
                            v-if="item.favorite"
                            :style="iconStyle"
                            src="/icons/favorite-icon.svg"
                            class="mr-1"
                            @click="favorite(item)"
                          />
                          <img
                            v-else
                            :style="iconStyle"
                            src="/icons/favorite-not-filled-icon.svg"
                            class="mr-1"
                            @click="favorite(item)"
                          />
                        </div>
                      </template>
                      <template v-slot:[`item.name`]="{ item }">
                        <div class="d-flex align-center">
                          <img
                            v-if="item.favorite"
                            :style="iconStyle"
                            src="/icons/favorite-icon.svg"
                            class="mr-1"
                            @click="favorite(item)"
                          />
                          <img
                            v-else
                            :style="iconStyle"
                            src="/icons/favorite-not-filled-icon.svg"
                            class="mr-1"
                            @click="favorite(item)"
                          />
                          <span>
                            {{ item.name || "Sem registro" }}
                          </span>
                        </div>
                      </template>

                      <template v-slot:[`item.actions`]="{ item }">
                        <div class="actions">
                          <v-tooltip bottom color="grey">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                class="mr-1"
                                @click="selectOptionFromTable(item, 1)"
                                >$edit_outline</v-icon
                              >
                            </template>
                            <span
                              style="
                                color: white;
                                padding: 0.5rem;
                                font-weight: 500;
                              "
                              >Renomear</span
                            >
                          </v-tooltip>

                          <v-tooltip bottom color="grey">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                class="mr-1"
                                @click="selectOptionFromTable(item, 5)"
                              >
                                $edit_rounded</v-icon
                              >
                            </template>
                            <span
                              style="
                                color: white;
                                padding: 0.5rem;
                                font-weight: 500;
                              "
                              >Editar</span
                            >
                          </v-tooltip>

                          <v-tooltip bottom color="grey">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                class="mr-1"
                                @click="selectOptionFromTable(item, 4)"
                              >
                                $refresh_rounded</v-icon
                              >
                            </template>
                            <span
                              style="
                                color: white;
                                padding: 0.5rem;
                                font-weight: 500;
                              "
                              >Atualizar</span
                            >
                          </v-tooltip>

                          <v-tooltip bottom color="grey">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                class="mr-1"
                                @click="selectOptionFromTable(item, 0)"
                                >$delete_outline_rounded</v-icon
                              >
                            </template>
                            <span
                              style="
                                color: white;
                                padding: 0.5rem;
                                font-weight: 500;
                              "
                              >Apagar</span
                            >
                          </v-tooltip>
                        </div>
                      </template>

                      <template v-slot:[`item.connectionName`]="{ item }">
                        <span>{{ getConnectionName(item) }} </span>
                      </template>

                      <template v-slot:[`item.created`]="{ item }">
                        <span>{{ formateDate(item.created) }} </span>
                      </template>

                      <template v-slot:[`item.status`]="{ item }">
                        <span
                          class="badge"
                          :style="setBadgeTheme(item.status || 'error')"
                        >
                          {{ stats[item?.status || "error"] }}
                        </span>
                      </template>
                    </z-table>
                  </div>
                </template>
              </z-pagination>
              <!-- <ZAccordion
                icon="mdi-star"
                img-path="/icons/favorite-icon.svg"
                icon-color="#FFC220"
                title="Favoritos"
                :quantity="getFavorites.length"
              >
                <div class="wrapper__card__body__cards">
                  <v-row dense>
                    <v-col
                      v-for="(item, i) in getFavorites"
                      :key="i"
                      cols="12"
                      sm="6"
                      md="6"
                      lg="4"
                    >
                      <z-card :title="item.name" width="100%" :shadow="true" class="item">
                        <template #actions>
                          <div class="d-flex align-center justify-center">
                            <img
                              v-if="item.favorite"
                              :style="iconStyle"
                              src="/icons/favorite-icon.svg"
                              class="mr-1"
                              @click="favorite(item)"
                            />
                            <img
                              v-else
                              :style="iconStyle"
                              src="/icons/favorite-not-filled-icon.svg"
                              class="mr-1"
                              @click="favorite(item)"
                            />
                            <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <img
                                  v-bind="attrs"
                                  v-on="on"
                                  :style="iconStyle2"
                                  src="/icons/3-dots-icon.svg"
                                />
                              </template>

                              <div class="list-options">
                                <div
                                  v-for="(option, index) in optionsMenu"
                                  :key="index"
                                  class="list-options__item"
                                  @click="selectDotOption(item, option)"
                                >
                                  <span>
                                    {{ option.text }}
                                  </span>
                                </div>
                              </div>
                            </v-menu>
                          </div>
                        </template>

                        <template #content>
                          <div style="margin-top: 1rem; display: flex; gap: 1rem 4rem">
                            <div class="block_1">
                              <span style="font-size: 0.9em">Usuários únicos</span>
                              <br />
                              <span style="font-size: 0.85em">{{
                                getCountText(item.uniqueCount)
                              }}</span>
                            </div>
                            <div class="block_2">
                              <span style="font-size: 0.9em">Ultima modificação:</span>
                              <br />
                              <span style="font-size: 0.85em">{{
                                formateDate(item.updated)
                              }}</span>
                            </div>
                          </div>
                        </template>
                      </z-card>
                    </v-col>
                  </v-row>
                </div>
              </ZAccordion>
              <ZAccordion
                icon="mdi-clock"
                icon-color="#FF8F28"
                title="Todos"
                :quantity="!searchConnection ? audiences.length : filtered.length"
              >
                <div class="wrapper__card__body__cards">
                  <template v-if="!searchConnection">
                    <v-row dense>
                      <v-col
                        v-for="(item, i) in audiences"
                        :key="i"
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                      >
                        <z-card
                          :title="item.name"
                          width="100%"
                          :shadow="true"
                          class="item"
                        >
                          <template #actions>
                            <div class="d-flex align-center justify-center">
                              <img
                                v-if="item.favorite"
                                :style="iconStyle"
                                src="/icons/favorite-icon.svg"
                                class="mr-1"
                                @click="favorite(item)"
                              />
                              <img
                                v-else
                                :style="iconStyle"
                                src="/icons/favorite-not-filled-icon.svg"
                                class="mr-1"
                                @click="favorite(item)"
                              />
                              <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                  <img
                                    v-bind="attrs"
                                    v-on="on"
                                    :style="iconStyle2"
                                    src="/icons/3-dots-icon.svg"
                                  />
                                </template>

                                <div class="list-options">
                                  <div
                                    v-for="(option, index) in optionsMenu"
                                    :key="index"
                                    class="list-options__item"
                                    @click="selectDotOption(item, option)"
                                  >
                                    <span>
                                      {{ option.text }}
                                    </span>
                                  </div>
                                </div>
                              </v-menu>
                            </div>
                          </template>

                          <template #content>
                            <div style="margin-top: 1rem; display: flex; gap: 1rem 4rem">
                              <div class="block_1">
                                <span style="font-size: 0.9em">Usuários únicos</span>
                                <br />
                                <span style="font-size: 0.85em">{{
                                  getCountText(item.uniqueCount)
                                }}</span>
                              </div>
                              <div class="block_2">
                                <span style="font-size: 0.9em">Ultima modificação:</span>
                                <br />
                                <span style="font-size: 0.85em">{{
                                  formateDate(item.updated)
                                }}</span>
                              </div>
                            </div>
                          </template>
                        </z-card>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-else>
                    <v-row dense>
                      <v-col
                        v-for="(item, i) in filtered"
                        :key="i"
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                      >
                        <z-card
                          :title="item.name"
                          width="100%"
                          :shadow="true"
                          class="item"
                        >
                          <template #actions>
                            <div class="d-flex align-center justify-center">
                              <img
                                v-if="item.favorite"
                                :style="iconStyle"
                                src="/icons/favorite-icon.svg"
                                class="mr-1"
                                @click="favorite(item)"
                              />
                              <img
                                v-else
                                :style="iconStyle"
                                src="/icons/favorite-not-filled-icon.svg"
                                class="mr-1"
                                @click="favorite(item)"
                              />
                              <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                  <img
                                    v-bind="attrs"
                                    v-on="on"
                                    :style="iconStyle2"
                                    src="/icons/3-dots-icon.svg"
                                  />
                                </template>

                                <div class="list-options">
                                  <div
                                    v-for="(option, index) in optionsMenu"
                                    :key="index"
                                    class="list-options__item"
                                    @click="selectDotOption(item, option)"
                                  >
                                    <span>
                                      {{ option.text }}
                                    </span>
                                  </div>
                                </div>
                              </v-menu>
                            </div>
                          </template>

                          <template #content>
                            <div style="margin-top: 1rem; display: flex; gap: 1rem 4rem">
                              <div class="block_1">
                                <span style="font-size: 0.9em">Usuários únicos</span>
                                <br />
                                <span style="font-size: 0.85em">{{
                                  getCountText(item.uniqueCount)
                                }}</span>
                              </div>
                              <div class="block_2">
                                <span style="font-size: 0.9em">Ultima modificação:</span>
                                <br />
                                <span style="font-size: 0.85em">{{
                                  formateDate(item.updated)
                                }}</span>
                              </div>
                            </div>
                          </template>
                        </z-card>
                      </v-col>
                    </v-row>
                  </template>
                </div>
              </ZAccordion> -->
            </div>
          </template>
          <template #audience-filtered>
            <div class="wrapper__card__body mt-3">
              <createdFilteredAudiences
                :search-connection-prop="searchConnection"
                :items-per-page-prop="getNumberOfElements"
                style="height: 100%"
              />
            </div>
          </template>
        </z-tabs>
      </div>

      <z-card-modal
        v-model="showAudienceModal"
        width="900"
        title="Como você quer começar?"
      >
        <template #content>
          <div
            class="cards-audience"
            style="
              display: flex;
              align-items: flex-start;
              gap: 1rem;
              padding: 1rem 0;
              flex-wrap: wrap;
              color: 333333;
            "
          >
            <div
              v-for="(item, i) in audienceModalData"
              :key="i"
              class="cards-audience__item"
              :style="cardAudienceStyle"
            >
              <div class="header" style="text-align: center">
                <figure style="width: 30px; height: 30px; margin: 0 auto">
                  <img
                    :src="item.icon"
                    style="width: 100%; height: 100%; object-fit: contain"
                  />
                </figure>
                <strong style="text-transform: uppercase; font-size: 0.95em">
                  {{ item.name }}
                </strong>
              </div>
              <div class="body" style="text-align: center; font-size: 0.94em">
                <span>{{ item.description }}</span>
              </div>
            </div>
          </div>
        </template>
      </z-card-modal>

      <z-card-modal v-model="dialogDelete" width="400" title="">
        <template #content>
          <div class="delete-confirmation">
            <div class="delete-confirmation__title mb-5">
              <span>Tem certeza que deseja apagar a conexão</span>
              <br />
              <strong>{{
                audienceToDelete?.name || "Sem nome resgistrado"
              }}</strong>
            </div>

            <div class="delete-confirmation--actions">
              <z-btn
                small
                rounded
                text="cancelar"
                @click="
                  dialogDelete = false;
                  resetaudienceToDelete();
                "
              />

              <z-btn
                rounded
                primary
                text="apagar"
                small
                :isLoading="loadingDelete"
                @click="confirmedDeleteAudience()"
              />
            </div>
          </div>
        </template>
      </z-card-modal>

      <z-card-modal v-model="dialogRename" width="400" title="">
        <template #content>
          <div v-if="audienceToUpdate?.id" class="delete-confirmation">
            <div class="delete-confirmation__title mb-1">
              <span class="mb-5">Insira o novo nome para a conexão:</span>
              <z-input v-model="audienceToUpdate.name" class="mt-3" />
            </div>

            <div class="delete-confirmation--actions">
              <z-btn
                small
                rounded
                text="cancelar"
                @click="
                  dialogRename = false;
                  resetAudienceToRename();
                "
              />

              <z-btn
                rounded
                primary
                text="Renomear"
                small
                :isLoading="loadingRename"
                @click="confirmUpdateNameAudience()"
              />
            </div>
          </div>
        </template>
      </z-card-modal>
    </div>
    <div v-show="!showInformations" class="wrapper no-data">
      <div class="wrapper__card">
        <z-loading />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ApiClient from "@/services/ApiClient";
import createdFilteredAudiences from "./createdFilteredAudiences.vue";
export default {
  components: {
    createdFilteredAudiences,
  },
  data() {
    return {
      sortDesc: false,
      stats: {
        pending: "Pendente",
        updated: "Atualizado",
        error: "Error",
        "in-progress": "Em progresso",
      },
      headers: [
        // { text: "", value: "favorite" },
        { text: "Nome", value: "name", sortable: true },
        { text: "Conexão", value: "connectionName", sortable: true },
        { text: "Usuários únicos", value: "uniqueCount", sortable: true },
        // { text: "Status", value: "status", sortable: false },
        { text: "Data de criação", value: "created", sortable: true },
        { text: "Ações", value: "actions" },
      ],
      tabs: [
        { id: "audience", text: "Audiência" },
        { id: "audience-filtered", text: "Audiência filtrada" },
      ],
      searchConnection: "",
      audienceToDelete: null,
      loadingDelete: false,
      http: new ApiClient(),
      showAudienceModal: false,
      showInformations: false,
      dialogDelete: false,
      dialogRename: false,
      audienceToUpdate: null,
      loadingRename: false,
      audiences: [],
      filtered: [],
      iconStyle: {
        width: "20px",
        height: "20px",
        cursor: "pointer",
      },
      iconStyle2: {
        width: "23px",
        height: "23px",
        cursor: "pointer",
      },
      optionsMenu: [
        { action: "delete", text: "Apagar", value: 0 },
        { action: "rename", text: "Renomear", value: 1 },
        // { action: "document", text: "Documentação", value: 2 },
        // { action: "log", text: "Ver log", value: 3 },
        { action: "att", text: "Atualizar", value: 4 },
      ],
      audienceModalData: [
        {
          name: "Criar audiência visão 360",
          description: "Criar uma nova audiência usando a visão 360.",
          icon: "/icons/people-icon.svg",
          to: "/",
        },
        {
          name: "Criar audiência filtrada",
          description: "Criar uma audiência com filtragem avançada.",
          icon: "/icons/search-icon.svg",
          to: "/",
        },
        {
          name: "Selecionar audiência",
          description: "Selecionar uma audiência já criada anteriormente.",
          icon: "/icons/folder-icon.svg",
          to: "/",
        },
      ],
      cardAudienceStyle: {
        background: "#FFFFFF",
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
        padding: "1rem",
        flex: "1 1 200px",
        cursor: "pointer",
      },
      lastWidth: 0,
      lastHeight: 508, // tamanho minimo para se ter dados,
    };
  },
  mounted() {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { width, height } = entry.contentRect;
        this.lastWidth = width;
        this.lastHeight = height;
      }
    });

    resizeObserver.observe(this.$refs.cardRef);
  },
  watch: {
    dialogDelete(nv) {
      if (!nv) this.resetaudienceToDelete();
    },
    searchConnection(nv) {
      this.filtered = this.getConnectionsByName(nv);
    },
  },
  created() {
    this.getAudiencesList();
  },
  computed: {
    getNumberOfElements() {
      const minimum_height = 550;
      let items_per_page = 8;

      if (this.lastHeight < minimum_height) items_per_page = 4;

      return items_per_page;
    },
    getFavorites() {
      return !this.searchConnection
        ? this.audiences.filter((item) => item.favorite == true)
        : this.filtered.filter((item) => item.favorite == true);
    },
  },
  methods: {
    setBadgeTheme(item) {
      const DEFAULT = {
        backgroundColor: "#d0294b",
        color: "#ffffff",
      };

      const STYLES = {
        updated: {
          backgroundColor: "#49c469",
          color: "#ffffff",
        },
        pending: {
          backgroundColor: "#FFC220",
          color: "#ffffff",
        },
        "in-progress": {
          backgroundColor: "#ff9320",
          color: "#ffffff",
        },
        error: { ...DEFAULT },
      };

      return STYLES[item] || DEFAULT;
    },
    getConnectionsByName(name) {
      return this.audiences.filter((item) =>
        item.name.toLocaleLowerCase().includes(name.toLocaleLowerCase())
      );
    },
    resetaudienceToDelete() {
      this.audienceToDelete = null;
    },
    resetAudienceToRename() {
      this.audienceToUpdate = null;
      this.dialogRename = false;
    },
    goToPage(name) {
      this.$router.push({ name });
    },
    getCountText(count) {
      if (!count) {
        return "0";
      } else if (count > 1000000) {
        return parseInt((count / 1000000) * 100) / 100 + "M";
      } else if (count > 1000) {
        return parseInt((count / 1000) * 100) / 100 + "K";
      } else {
        return count;
      }
    },
    getConnectionName(item) {
      if (item.databaseConfig) return item.databaseConfig.name;
      else return "-";
    },
    formateDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    selectOptionFromTable(data, optionData) {
      switch (optionData) {
        case 0:
          this.deleteAudience(data);
          break;
        case 1:
          this.updateAudience(data);
          break;
        case 2:
          this.$router.push({
            name: "docsIndexPage",
            params: { page: "primaryData", id: data.id },
          });
          break;
        case 3:
          this.$router.push({
            name: "logsIndexPage",
            params: { page: "primaryData", id: data.id },
          });
          break;
        case 4:
          this.actualizeUniqueAudienceData(data);
          break;
        case 5:
          this.$router.push({
            name: "editAudiencePage",
            params: { type: "audience", id: data.id },
          });
          break;
      }
    },
    // selectDotOption(data, optionData) {
    //   switch (optionData.value) {
    //     case 0:
    //       this.deleteAudience(data);
    //       break;
    //     case 1:
    //       this.updateAudience(data);
    //       break;
    //     case 2:
    //       this.$router.push({
    //         name: "docsIndexPage",
    //         params: { page: "primaryData", id: data.id },
    //       });
    //       break;
    //     case 3:
    //       this.$router.push({
    //         name: "logsIndexPage",
    //         params: { page: "primaryData", id: data.id },
    //       });
    //       break;
    //     case 4:
    //       this.actualizeUniqueAudienceData(data);
    //       break;
    //   }
    // },
    async actualizeUniqueAudienceData(audience) {
      try {
        const req = await this.http.post(
          "audience/refreshAudienceUniqueCount",
          {
            id: audience.id,
          }
        );

        audience.uniqueCount = req;

        this.$toast.success("Audiência atualizada com sucesso!");
        // console.log(req);
      } catch (error) {
        this.$toast.error("Erro ao atualizar infomação");
      }
    },
    deleteAudience(audience) {
      this.audienceToDelete = { ...audience };
      this.dialogDelete = true;
    },
    updateAudience(audience) {
      this.audienceToUpdate = { ...audience };
      this.dialogRename = true;
    },
    async confirmedDeleteAudience() {
      this.loadingDelete = true;
      try {
        const req = await this.http.delete(
          `audience/${this.audienceToDelete.id}`
        );
        if (req) {
          this.$toast.success("Audiência removida com sucesso!");
          this.resetaudienceToDelete();
          await this.getAudiencesList();
          this.filtered = this.getConnectionsByName(this.searchConnection);
          this.dialogDelete = false;
        }
        this.loadingDelete = false;
      } catch (error) {
        this.$toast.error("Erro ao deletar audiência");
        this.loadingDelete = false;
      }
    },
    async confirmUpdateNameAudience() {
      this.loadingRename = true;
      try {
        const req = await this.http.post(`audience/rename`, {
          id: this.audienceToUpdate.id,
          name: this.audienceToUpdate.name,
        });
        if (req) {
          this.$toast.success("Audiência atualizada com sucesso!");
          this.getAudiencesList();
          this.loadingRename = false;
          this.resetAudienceToRename();
        }
        this.loadingRename = false;
        this.audienceToUpdate = null;
      } catch (error) {
        this.$toast.error("Erro ao renomear audiência");
        this.loadingRename = false;
        this.getAudiencesList();
      }
    },
    async getAudiencesList() {
      try {
        const req = await this.http.get("audience/list");
        this.audiences = req;
        this.showInformations = true;
      } catch (error) {
        this.$toast.error("Erro ao listar audiências");
      }
    },
    async sortTable(nameToSort) {
      this.sortDesc = !this.sortDesc;
      try {
        const ORDER = this.sortDesc ? "ASC" : "DESC";
        const response = await this.http.get(
          `audience/list?sort=${nameToSort}&order=${ORDER}`
        );
        if (response) {
          this.audiences = response;
          if (this.searchConnection) {
            this.filtered =
              this.getConnectionsByName(this.searchConnection) || [];
          }
        }
      } catch (error) {
        this.$toast.error("Erro ao ordenar dados");
      }
    },
    // async getAudiencesFilteredList() {
    //   try {
    //     const req = await this.http.get("audienceFilter/list");
    //     this.audiences = req;
    //     this.showInformations = true;
    //   } catch (error) {
    //     this.$toast.error("Erro ao buscar audiências filtradas");
    //   }
    // },
    async favorite(item) {
      let fav = item.favorite;
      if (fav == null || fav == undefined) {
        fav = true;
        try {
          await this.http.post("audience/toggleFavorite", {
            id: item.id,
            favorite: fav,
          });

          this.getAudiencesList();
        } catch (error) {
          this.$toast.error("Erro ao realizar ação");
        }
      } else {
        fav = !fav;
        try {
          await this.http.post("audience/toggleFavorite", {
            id: item.id,
            favorite: fav,
          });

          this.getAudiencesList();
        } catch (error) {
          this.$toast.error("Erro ao realizar ação");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.wrapper {
  color: $z-black-1;
  padding: $z-s-1;
  height: calc(100vh - 48px);

  &__card {
    padding: $z-s-1;
    background: #ffffff;
    border-radius: 15px;
    height: 100%;
    overflow-y: hidden;

    &__header {
      display: flex;
      align-items: center;
      gap: $z-s-1;
      margin-bottom: $z-s-1;
      flex-wrap: wrap;
      .left {
        flex: 1 1 auto;
        span {
          font-weight: 700;
          font-size: 1.1em;
          line-height: 15px;
        }
      }
      .right {
        flex: 2 1 600px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        gap: $z-s-1 0.5rem;

        @media (max-width: 808px) {
          justify-content: flex-start;
          & > div {
            flex: 1 1 350px;
          }
        }
      }
    }
    &__body {
      display: flex;
      flex-direction: column;
      gap: $z-s-1;
      height: 90%;
      // border: 2px solid blue;

      .table-wrapper {
        height: 450px !important;
        max-height: 500px;
        overflow-y: auto;
        @include trackScrollBar;

        .no-data-table {
          text-align: center;
        }

        ::v-deep .v-data-table {
          table {
            th {
              &:first-child {
                min-width: 300px;
                max-width: 300px;
              }
              &:nth-child(2) {
                min-width: 250px;
                max-width: 250px;
              }
              &:not(:first-child) {
                text-align: right !important;
              }
            }
            td {
              &:first-child {
                min-width: 300px;
                max-width: 300px;
              }
              &:nth-child(2) {
                min-width: 250px;
                max-width: 250px;
              }

              &:not(:first-child) {
                text-align: right !important;
              }
            }
          }
        }

        @media (min-width: 1550px) {
          height: auto;
          max-height: 650px;
        }
      }

      @media (min-width: 720px) and (max-width: 1079px) {
        .table-wrapper {
          height: 380px;
          max-height: 380px;
        }
      }

      @media (max-width: 890px) {
        height: auto;
        .table-wrapper {
          height: 330px;
          max-height: 330px;
        }
      }

      // // NOVAS TABELAS ADICIONADAS
      @media (min-width: 750px) {
        ::v-deep .v-data-table {
          table {
            th {
              &:not(:first-child) {
                text-align: center !important;
              }
              &:last-child {
                text-align: right !important;
              }
            }
            td {
              &:not(:first-child) {
                text-align: center !important;
                & > div,
                span {
                  margin: 0 auto !important;
                }
              }
              &:last-child {
                text-align: right !important;
              }
            }
          }
        }
      }
      // .filtered {
      //   height: 100%;
      // }
    }
  }
  @media (max-width: 850px) {
    height: auto;
  }
}

.list-options {
  background: #ffffff;
  &__item {
    padding: 0.5rem;
    cursor: pointer;
    transition: 0.2s ease;
    span {
      font-weight: 500;
    }
    &:hover {
      background: #e2e2e2;
    }
  }
}

.delete-confirmation {
  padding: 1rem;
  &__title {
    text-align: center;
    font-weight: 500;
  }
  &--actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
}
.no-data {
  .wrapper__card {
    height: 50vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-weight: 700;
      font-size: 1.1em;
      line-height: 15px;
    }
  }
}

.badge {
  padding: 0.03rem 0.45rem;
  border-radius: 50px;
  font-size: 0.75rem;
  font-weight: 500;
  transition: 0.2s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  &:hover {
    // background: rgb(236, 236, 236);
  }
}
</style>
